










import Vue from 'vue'
import Component from 'vue-class-component'

enum networks {
  MAIN = 'mainnet',
  GRANADA = 'granadanet'
}

@Component
export default class MainPage extends Vue {
  private network = networks.MAIN

  mounted(): void {
    const newNetwork = this.$route.query.network
    if (Object.values(networks).includes(newNetwork as networks) && newNetwork !== 'mainnet'){
      this.network = newNetwork as networks
    } else {
      // Redirect without any query to default to mainnet
      this.$router.push(this.$route.path)
    }
  }
}
